/* External dependencies */
import { useDispatch } from 'react-redux';
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */
import blockAccountEpic from '../components/accounts/blockAccount/epics';
import blockAccount from '../components/accounts/blockAccount/reducer';
import listAccountsEpic from '../components/accounts/listAccounts/epics';
import accounts from '../components/accounts/listAccounts/reducer';
import listRootAccountsEpic from '../components/accounts/listRootAccounts/epics';
import rootAccounts from '../components/accounts/listRootAccounts/reducer';
import topUpBalanceEpic from '../components/accounts/topUpAccountBalance/epics';
import topUpBalance from '../components/accounts/topUpAccountBalance/reducer';
import { addRequiredFieldsEpic } from '../components/acquiring/acquiring-payment/redux/epic';
import createAnOrder from '../components/acquiring/acquiring-payment/redux/reducer';
import getAppClientEpic from '../components/appClients/getAppClient/redux/epic';
import getAppClient from '../components/appClients/getAppClient/redux/reducer';
import getAccountsBalanceEpic from '../components/dashboard/redux/epics';
import getAccountBalanceReducer from '../components/dashboard/redux/reducer';
import listTechniciansEpic from '../components/devices/terminals/listTechnicians/redux/epic';
import listTechnicians from '../components/devices/terminals/listTechnicians/redux/reducer';
import listTerminalsEpic from '../components/devices/terminals/listTerminals/epics';
import terminals from '../components/devices/terminals/listTerminals/reducer';
import { getTerminalEpic, updateTerminalEpic } from '../components/devices/terminals/updateTerminal/redux/epic';
import updateTerminal from '../components/devices/terminals/updateTerminal/redux/reducer';
import distributionFeesEpic from '../components/fees/distributionFees/redux/epic';
import distributionFees from '../components/fees/distributionFees/redux/reducer';
import { serviceLowerFees, serviceUpperFees } from '../components/fees/redux/reducer';
import serviceFeesEpic from '../components/fees/serviceFees/redux/epic';
import servicesFees from '../components/fees/serviceFees/redux/reducer';
import { getServiceRecipientEpic } from '../components/getServiceRecipient/redux/epic';
import getServiceRecipient from '../components/getServiceRecipient/redux/reducer';
import createLimitEpic from '../components/limits/createLimit/redux/epic';
import limits from '../components/limits/createLimit/redux/reducer';
import getLimitEpic from '../components/limits/getLimit/redux/epics';
import getLimit from '../components/limits/getLimit/redux/reducer';
import listLimitsEpic from '../components/limits/listLimits/epics';
import listLimits from '../components/limits/listLimits/reducer';
import updateLimitEpic from '../components/limits/updateLimit/redux/epic';
import { initClientEpic, loginEpic, logoutEpic, sendVerificationCodeEpic } from '../components/login/redux/epics';
import login, { LoginState } from '../components/login/redux/reducer';
import deleteDistributionFeesEpic from '../components/serviceDistributionFees/redux/deleteDistributionFees/epics';
import deleteDistributionFees from '../components/serviceDistributionFees/redux/deleteDistributionFees/reducer';
import getDistributionFeeEpic from '../components/serviceDistributionFees/redux/getDistributionFees/epics';
import getDistributionFee from '../components/serviceDistributionFees/redux/getDistributionFees/reducer';
import listDistributionFeesEpic from '../components/serviceDistributionFees/redux/listDistributionFees/epics';
import listDistributionFees from '../components/serviceDistributionFees/redux/listDistributionFees/reducer';
import deleteServiceFeesEpic from '../components/serviceFees/deleteServiceFees/epics';
import deleteServiceFees from '../components/serviceFees/deleteServiceFees/reducer';
import getServiceFeesEpic from '../components/serviceFees/getServiceFees/redux/epics';
import getServiceFees from '../components/serviceFees/getServiceFees/redux/reducer';
import listServiceFeesEpic from '../components/serviceFees/listServiceFees/epics';
import listServiceFees from '../components/serviceFees/listServiceFees/reducer';
import activeServiceEpic from '../components/services/activeService/epics';
import activeService from '../components/services/activeService/reducer';
import blockServiceEpic from '../components/services/blockService/epics';
import blockService from '../components/services/blockService/reducer';
import createCategoryEpic from '../components/services/createCategory/redux/epic';
import createCategory from '../components/services/createCategory/redux/reducer';
import createServiceEpic from '../components/services/createService/redux/epics';
import createService from '../components/services/createService/redux/reducer';
import createServiceEndpointEpic from '../components/services/createServiceEndpoint/redux/epics';
import createServiceEndpoint from '../components/services/createServiceEndpoint/redux/reducer';
import { deleteCategoryEpic, deleteServiceEpic } from '../components/services/deleteService/redux/epics';
import deleteService from '../components/services/deleteService/redux/reducer';
import listServiceEndpointsEpic from '../components/services/listServiceEndpoint/epics';
import serviceEndpoints from '../components/services/listServiceEndpoint/reducer';
import listServicesEpic from '../components/services/listServices/epics';
import services from '../components/services/listServices/reducer';
import { getCategoryEpic, updateCategoryEpic } from '../components/services/updateCategory/redux/epic';
import updateCategory from '../components/services/updateCategory/redux/reducer';
import { getServiceEpic, updateServiceEpic } from '../components/services/updateService/redux/epics';
import updateService from '../components/services/updateService/redux/reducer';
import cancelPaymentEpic from '../components/transactions/cancelPayment/epics';
import cancelPayment from '../components/transactions/cancelPayment/reducer';
import listTransactionsEpic, {
  listPaymentsEpic,
  listTransactionsUserEpic,
} from '../components/transactions/listTransactions/epics';
import transactions from '../components/transactions/listTransactions/reducer';
import transactionsQueueMetricsEpic, {
  redriveInProgressQueueMetricsEpic,
  redrivePendingQueueMetricsEpic,
} from '../components/transactions/queueMetrics/redux/epics';
import transactionsQueueMetrics from '../components/transactions/queueMetrics/redux/reducer';
import rewirePaymentEpic from '../components/transactions/rewirePayment/epics';
import rewirePayment from '../components/transactions/rewirePayment/reducer';
import getProfileEpic from '../components/users/getProfile/epics';
import getProfile from '../components/users/getProfile/reducer';
import listUserEpic, { listUsersForManagerEpic } from '../components/users/listUsers/epics';
import users from '../components/users/listUsers/reducer';
import getUserEpic, {
  getRootUserEpic,
  updateUserForAdminEpic,
  updateUserForComplianceManagerEpic,
  updateUserForManagerEpic,
  updateUserForOperatorEpic,
} from '../components/users/user-details/redux/epics';
import getUser from '../components/users/user-details/redux/reducer';

export type ApplicationState = {
  login: LoginState;
};

const rootEpic = combineEpics(
  activeServiceEpic,
  addRequiredFieldsEpic,
  blockAccountEpic,
  blockServiceEpic,
  cancelPaymentEpic,
  createCategoryEpic,
  createLimitEpic,
  createServiceEndpointEpic,
  createServiceEpic,
  distributionFeesEpic,
  deleteDistributionFeesEpic,
  deleteServiceFeesEpic,
  getAppClientEpic,
  getCategoryEpic,
  getLimitEpic,
  getProfileEpic,
  getRootUserEpic,
  getServiceEpic,
  getTerminalEpic,
  getUserEpic,
  getServiceRecipientEpic,
  getDistributionFeeEpic,
  getAccountsBalanceEpic,
  initClientEpic,
  listAccountsEpic,
  listRootAccountsEpic,
  listDistributionFeesEpic,
  listLimitsEpic,
  listServiceEndpointsEpic,
  listServicesEpic,
  listTechniciansEpic,
  listTerminalsEpic,
  listTransactionsEpic,
  listPaymentsEpic,
  listTransactionsUserEpic,
  listUserEpic,
  listUsersForManagerEpic,
  loginEpic,
  logoutEpic,
  serviceFeesEpic,
  rewirePaymentEpic,
  sendVerificationCodeEpic,
  topUpBalanceEpic,
  updateCategoryEpic,
  updateLimitEpic,
  updateServiceEpic,
  updateTerminalEpic,
  updateUserForAdminEpic,
  updateUserForManagerEpic,
  updateUserForComplianceManagerEpic,
  updateUserForOperatorEpic,
  deleteCategoryEpic,
  deleteServiceEpic,
  redriveInProgressQueueMetricsEpic,
  redrivePendingQueueMetricsEpic,
  transactionsQueueMetricsEpic,
  listServiceFeesEpic,
  getServiceFeesEpic,
);

const rootReducer = combineReducers({
  accounts,
  activeService,
  blockAccount,
  blockService,
  cancelPayment,
  createAnOrder,
  createCategory,
  createService,
  createServiceEndpoint,
  deleteDistributionFees,
  deleteService,
  deleteServiceFees,
  distributionFees,
  getAppClient,
  getAccountBalanceReducer,
  getLimit,
  getProfile,
  getServiceRecipient,
  getUser,
  getDistributionFee,
  limits,
  listDistributionFees,
  listServiceFees,
  listTechnicians,
  login,
  rewirePayment,
  getServiceFees,
  transactionsQueueMetrics,
  rootAccounts,
  serviceEndpoints,
  serviceLowerFees,
  services,
  servicesFees,
  serviceUpperFees,
  terminals,
  topUpBalance,
  transactions,
  updateCategory,
  updateService,
  updateTerminal,
  users,
  listLimits,
});

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
