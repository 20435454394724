/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import { getEndOfDay } from '../helper';
import {
  ListTransactions,
  ListTransactionsAction,
  ListTransactionsActionTypes,
  listTransactionsFailed,
  listTransactionsSucceeded,
  ListTransactionsSuccess,
} from './actions';
import { listPaymentsQuery, listTransactionsQuery } from './queries';
import { Transaction } from './types';

export function listPaymentsEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListTransactionsAction) => action.type === ListTransactionsActionTypes.LIST_PAYMENTS_REQUEST),
    debounceTime(500),
    switchMap((action: ListTransactions) =>
      listPayments(action, state$.value.transactions?.size).catch((error) => listTransactionsFailed(error)),
    ),
  );
}

export default function listTransactionsEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListTransactionsAction) => action.type === ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST),
    debounceTime(500),
    switchMap((action: ListTransactions) =>
      listTransactions(action, state$.value.transactions?.size).catch((error) => listTransactionsFailed(error)),
    ),
  );
}

export async function listTransactions(
  { currentPage, filter, locale, searchString, startDate, endDate }: ListTransactions,
  size: number,
): Promise<ListTransactionsSuccess> {
  const graphQLClient = await getClient();

  const input = {
    filter,
    from: (currentPage - 1) * size,
    locale,
    query: searchString,
    size,
    ...(startDate ? { startDate: new Date(startDate).getTime() } : {}),
    ...(endDate ? { endDate: getEndOfDay(new Date(endDate)).getTime() } : {}),
  };

  const response = await graphQLClient.query({
    errorPolicy: 'none',
    query: listTransactionsQuery,
    variables: {
      input,
    },
  });

  const {
    data: {
      listTransactions: { total, payments },
    },
  } = response;

  const filteredPayments = payments.filter((payment: Transaction) => payment);

  return listTransactionsSucceeded(total, filteredPayments);
}

export async function listPayments(
  { currentPage, filter, locale, searchString, startDate, endDate }: ListTransactions,
  size: number,
): Promise<ListTransactionsSuccess> {
  const graphQLClient = await getClient();

  const input = {
    filter,
    from: (currentPage - 1) * size,
    locale,
    query: searchString,
    size,
    ...(startDate ? { startDate: new Date(startDate).getTime() } : {}),
    ...(endDate ? { endDate: getEndOfDay(new Date(endDate)).getTime() } : {}),
  };

  const response = await graphQLClient.query({
    query: listPaymentsQuery,
    variables: {
      input,
    },
  });

  const {
    data: {
      listPayments: { total, payments },
    },
  } = response;

  const filteredPayments = payments.filter((payment: Transaction) => payment);

  return listTransactionsSucceeded(total, filteredPayments);
}

export function listTransactionsUserEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListTransactionsAction) => action.type === ListTransactionsActionTypes.LIST_TRANSACTIONS_USER_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListTransactions) =>
      listTransactionsUser(action, state$.value.transactions?.size).catch((error) => listTransactionsFailed(error)),
    ),
  );
}

export async function listTransactionsUser(
  { startDate, endDate, currentPage, filter, locale, searchString }: ListTransactions,
  size: number,
): Promise<ListTransactionsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listTransactions: { total, payments },
    },
  } = await graphQLClient.query({
    query: listTransactionsQuery,
    variables: {
      input: {
        filter,
        from: (currentPage - 1) * size,
        locale,
        query: searchString,
        size,
        startDate: new Date(startDate).getTime(),
        endDate: new Date(endDate).getTime(),
      },
    },
  });

  return listTransactionsSucceeded(total, payments);
}
