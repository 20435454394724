/* Local dependencies */
import { Transaction, TransactionStatus, TransactionType } from './types';

export enum ListTransactionsActionTypes {
  LIST_PAYMENTS_REQUEST = 'LIST_PAYMENTS_REQUEST',
  LIST_TRANSACTIONS_REQUEST = 'LIST_TRANSACTIONS_REQUEST',
  LIST_TRANSACTIONS_USER_REQUEST = 'LIST_TRANSACTIONS_USER_REQUEST',
  LIST_TRANSACTIONS_SUCCESS = 'LIST_TRANSACTIONS_SUCCESS',
  LIST_TRANSACTIONS_ERROR = 'LIST_TRANSACTIONS_ERROR',
  LIST_TRANSACTIONS_ERROR_RESET = 'LIST_TRANSACTIONS_ERROR_RESET',
  SORT_TRANSACTIONS_BY = 'SORT_TRANSACTIONS_BY',
  SET_TRANSACTIONS_START_DATE = 'SET_TRANSACTIONS_START_DATE',
  SET_TRANSACTIONS_END_DATE = 'SET_TRANSACTIONS_END_DATE',
  DEBIT_PAYMENT_TYPE = 'DEBIT_PAYMENT_TYPE',
}

export interface ListPaymentsInput {
  endDate: Date;
  filter?: ListPaymentsFilter;
  from?: number;
  locale: string;
  query?: string;
  size?: number;
  startDate: Date;
}

export interface ListPaymentsFilter {
  status?: TransactionStatus[];
  type?: TransactionType;
}

export interface ListTransactionsInput {
  startDate?: Date;
  endDate?: Date;
  locale?: string;
  searchString?: string;
  currentPage?: number;
  filter?: ListTransactionsFilter;
}

export interface ListTransactions {
  type: ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST;
  startDate?: Date;
  endDate?: Date;
  locale?: string;
  searchString?: string;
  currentPage?: number;
  filter?: ListTransactionsFilter;
}


export interface ListPayments extends Omit<ListTransactions, 'type'>{
  type: ListTransactionsActionTypes.LIST_PAYMENTS_REQUEST;
}


export interface ListTransactionsUser {
  type: ListTransactionsActionTypes.LIST_TRANSACTIONS_USER_REQUEST;
  startDate?: Date;
  endDate?: Date;
  locale?: string;
  searchString?: string;
  currentPage?: number;
  filter?: ListTransactionsFilter;
}

export interface ListTransactionsSuccess {
  type: ListTransactionsActionTypes.LIST_TRANSACTIONS_SUCCESS;
  payments: Transaction[];
  total: number;
}

export interface ListTransactionsError {
  type: ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR;
  error: Error;
}

export interface ListTransactionsErrorReset {
  type: ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR_RESET;
}

export interface ListTransactionsFilter {
  accountId?: string;
  status?: TransactionStatus[];
  type?: TransactionType;
  userId?: string;
  service?: { id: string };
}

export const DEFAULT_PAYMENT_TYPE: ListTransactionsFilter = {
  type: TransactionType.CREDIT,
};

export const DEBIT_PAYMENT_TYPE: ListTransactionsFilter = {
  type: TransactionType.DEBIT,
};

export interface ListPaymentsSort {
  type: ListTransactionsActionTypes.SORT_TRANSACTIONS_BY;
  field: string;
}

export interface TransactionsStartDate {
  type: ListTransactionsActionTypes.SET_TRANSACTIONS_START_DATE;
  startDate: Date;
}

export interface TransactionsEndDate {
  type: ListTransactionsActionTypes.SET_TRANSACTIONS_END_DATE;
  endDate: Date;
}

export type ListTransactionsAction =
  | ListTransactions
  | ListTransactionsUser
  | ListTransactionsSuccess
  | ListTransactionsError
  | ListTransactionsErrorReset
  | TransactionsStartDate
  | TransactionsEndDate
  | ListPayments;


export function listPayments({
     locale,
     searchString = '',
     currentPage = 1,
     filter,
     startDate,
     endDate,
   }: ListTransactionsInput): ListPayments {
  return {
    type: ListTransactionsActionTypes.LIST_PAYMENTS_REQUEST,
    locale,
    searchString,
    currentPage,
    filter,
    startDate,
    endDate,
  };
}

export function listTransactions({
  locale,
  searchString = '',
  currentPage = 1,
  filter,
  startDate,
  endDate,
}: ListTransactionsInput): ListTransactions {
  return {
    type: ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST,
    locale,
    searchString,
    currentPage,
    filter,
    startDate,
    endDate,
  };
}

export function listTransactionsUser(
  locale?: string,
  searchString?: string = '',
  currentPage?: number = 1,
  filter: ListTransactionsFilter,
  startDate?: Date,
  endDate?: Date,
): ListTransactionsUser {
  return {
    type: ListTransactionsActionTypes.LIST_TRANSACTIONS_USER_REQUEST,
    locale,
    searchString,
    currentPage,
    filter,
    startDate,
    endDate,
  };
}

export function listTransactionsSucceeded(total, payments): ListTransactionsSuccess {
  return {
    type: ListTransactionsActionTypes.LIST_TRANSACTIONS_SUCCESS,
    payments,
    total,
  };
}

export function listTransactionsFailed(error: Error): ListTransactionsError {
  return {
    type: ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR,
    error,
  };
}


export function listTransactionsFailedReset(): ListTransactionsErrorReset {
  return {
    type: ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR_RESET,
  };
}


export function sortTransactionsBy(field: string) {
  return {
    type: ListTransactionsActionTypes.SORT_TRANSACTIONS_BY,
    field,
  };
}

export function setStartDate(startDate: Date): TransactionsStartDate {
  return {
    type: ListTransactionsActionTypes.SET_TRANSACTIONS_START_DATE,
    startDate,
  };
}

export function setEndDate(endDate: Date): TransactionsEndDate {
  return {
    type: ListTransactionsActionTypes.SET_TRANSACTIONS_END_DATE,
    endDate,
  };
}

